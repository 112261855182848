import Link from "next/link";
import MobileMenuContent from "./MobileMenuContent";
import { useSelector } from "react-redux";
import { useContext, useMemo } from "react";
import UserContext from "../../../features/user/UserContext";

const MobileMenu = () => {
  const { user } = useContext(UserContext);
  const { token } = useSelector((state) => state.auth);

  const maxNameChars = useMemo(() => 20);

  return (
    // <!-- Main Header Nav For Mobile -->
    <div className="stylehome1 h0 mega-menu-wrapper">
      <div className="mobile-menu">
        <div className="header stylehome1">
          <div className="main_logo_home2 text-center">
            <Link href="/">
              <img
                className="nav_logo_img img-fluid mt20"
                src="/assets/images/logo/listabiz-logo-only-transparent-black.png"
                alt="header-logo2.png"
                style={{ height: "50px" }}
              />
            </Link>
            {/* <span className="mt20">LiztaBiz</span> */}
          </div>
          {/* main_logo_home2 */}

          <ul className="menu_bar_home2">
            <li
              className={`list-inline-item list_s dropdown ${
                user && token ? " !right-[65px]" : null
              }`}>
              {!(user && token) ? (
                <Link href="/login">
                  <a>
                    <span className="flaticon-user"></span>
                  </a>
                </Link>
              ) : (
                <>
                  <a
                    className="dropbtn !mt-4 !p-0"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#DashboardOffcanvasMenu"
                    aria-controls="DashboardOffcanvasMenu">
                    <img
                      className="rounded-circle h-full object-cover"
                      src={
                        user.profile_pic
                          ? user.profile_pic
                          : `https://ui-avatars.com/api/?name=${user.fname}+${user.lname}`
                      }
                      alt="e1.png"
                    />
                    {/* <span className="flaticon-user"></span> */}
                    {/* <span className="leading-none">
                      {(user.fname + " " + user.lname).length <= maxNameChars
                        ? user.fname + " " + user.lname
                        : (user.fname + " " + user.lname).slice(
                            0,
                            maxNameChars - 1
                          ) + "..."}
                    </span> */}
                  </a>
                </>
              )}
            </li>
            <li
              className="list-inline-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasMenu"
              aria-controls="offcanvasMenu">
              <a>
                <span></span>
              </a>
            </li>
          </ul>
          {/* menu_bar_home2 */}
        </div>
      </div>
      {/* <!-- /.mobile-menu --> */}

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasMenuLabel"
        data-bs-scroll="true">
        <MobileMenuContent />
      </div>
    </div>
  );
};

export default MobileMenu;
