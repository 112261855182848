import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";

import UserContext from "../../../features/user/UserContext";
import { useContext, useState } from "react";

import { clearUserDetails } from "../../../features/user/userSlice";
import { clearToken } from "../../../features/auth/authSlice";

import MyAccount from "./dashboard/MyAccount";

const HeaderMenuContent = ({ float = "" }) => {
  const { user } = useContext(UserContext);

  const { token } = useSelector((state) => state.auth);

  const route = useRouter();
  const dispatch = useDispatch();

  const showLogin =
    !token && route.pathname !== "/login" && route.pathname !== "/register";

  return (
    <ul
      id="respMenu"
      className="ace-responsive-menu text-end d-lg-block d-none"
      data-menu-style="horizontal">
      <li className="last">
        <Link href="/listing/search">
          <a
            className={
              route.pathname === "/listing/search" ? "ui-active" : undefined
            }>
            Lizta
          </a>
        </Link>
      </li>

      {/* <li className="dropitem">
        <a
          href="#"
          className={
            home.some((page) => page.routerPath === route.pathname)
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Home</span>
          <span className="arrow"></span>
        </a>
        

        <ul className="sub-menu ">
          {home.map((item) => (
            <li key={item.id}>
              <Link href={item.routerPath}>
                <a
                  className={
                    route.pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </li> */}
      {/* End .dropitem */}

      {/* <li className="dropitem">
        <a
          href="#"
          className={
            listing.some((parent) => {
              return parent.items.some(
                (page) => page.routerPath === route.pathname
              );
            })
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Listing</span>
          <span className="arrow"></span>
        </a> */}
      {/* <!-- Level Two--> */}
      {/* <ul className="sub-menu ">
          {listing.map((item) => (
            <li className="dropitem arrow" key={item.id}>
              <a
                href="#"
                className={
                  item.items.some((page) => page.routerPath === route.pathname)
                    ? "ui-active"
                    : undefined
                }
              >
                {item.title}
              </a> */}
      {/* <!-- Level Three--> */}
      {/* <ul className="sub-menu ">
                {item.items.map((val, i) => (
                  <li key={i}>
                    <Link href={val.routerPath}>
                      <a
                        className={
                          route.pathname === val.routerPath
                            ? "ui-active"
                            : undefined
                        }
                      >
                        {val.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </li> */}
      {/* End .dropitem */}

      {/* <li className="dropitem">
        <a
          href="#"
          className={
            property.some((parent) => {
              return parent.items.some(
                (page) =>
                  page.routerPath === route.pathname ||
                  page.routerPath + "/[id]" === route.pathname
              );
            })
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Property</span>{" "}
          <span className="arrow"></span>
        </a>
        <ul className="sub-menu ">
          {property.map((item) => (
            <li className="dropitem arrow" key={item.id}>
              <a
                href="#"
                className={
                  item.items.some(
                    (page) =>
                      page.routerPath === route.pathname ||
                      page.routerPath + "/[id]" === route.pathname
                  )
                    ? "ui-active"
                    : undefined
                }
              >
                {item.title}
              </a> */}
      {/* <!-- Level Three--> */}
      {/* <ul className="sub-menu ">
                {item.items.map((val, i) => (
                  <li key={i}>
                    <Link href={val.routerPath}>
                      <a
                        className={
                          route.pathname === val.routerPath ||
                          val.routerPath + "/[id]" === route.pathname
                            ? "ui-active"
                            : undefined
                        }
                      >
                        {val.name}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </li> */}
      {/* End .dropitem */}

      {/* <li className="dropitem">
        <a
          href="#"
          className={
            pages.some((page) => page.routerPath === route.pathname)
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Pages</span>
          <span className="arrow"></span>
        </a>
        <ul className="sub-menu ">
          {pages.map((item) => (
            <li key={item.id}>
              <Link href={item.routerPath}>
                <a
                  className={
                    route.pathname === item.routerPath ? "ui-active" : undefined
                  }
                >
                  {item.name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </li> */}
      {/* End .dropitem */}

      {/* <li className="dropitem">
        <a
          href="#"
          className={
            blog.some(
              (page) =>
                page.routerPath === route.pathname ||
                page.routerPath + "/[id]" === route.pathname
            )
              ? "ui-active"
              : undefined
          }
        >
          <span className="title">Blog</span>
          <span className="arrow"></span>
        </a>
        <ul className="sub-menu ">
          {blog.map((item) => (
            <li key={item.id}>
              <Link href={item.routerPath}>
                <a
                  className={
                    route.pathname === item.routerPath ||
                    item.routerPath + "/[id]" === route.pathname
                      ? "ui-active"
                      : undefined
                  }
                >
                  {item.name}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </li> */}
      {/* End .dropitem */}

      <li>
        <Link href="/about-us">
          <a
            className={
              route.pathname === "/about-us" ? "ui-active" : undefined
            }>
            About Us
          </a>
        </Link>
      </li>
      <li className="last">
        <Link href="/contact">
          <a
            className={route.pathname === "/contact" ? "ui-active" : undefined}>
            Contact
          </a>
        </Link>
      </li>

      {/* End .dropitem */}

      {showLogin ? (
        <li className={`list-inline-item list_s ${float}`}>
          <a
            href="#"
            className="btn flaticon-user"
            data-bs-toggle="modal"
            data-bs-target=".bd-example-modal-lg">
            <span className="dn-lg">Login</span>
          </a>
        </li>
      ) : user && token.length ? (
        <li className="user_setting">
          <div className="dropdown">
            <a
              className="btn dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown">
              <img
                className="rounded-circle"
                style={{ width: "45px", height: "45px", objectFit: "cover" }}
                src={
                  user.profile_pic
                    ? user.profile_pic
                    : `https://ui-avatars.com/api/?name=${user.fname}+${user.lname}`
                }
                alt="e1.png"
              />
              <span className="dn-1199 ms-1">
                {user.fname} {user.lname}
              </span>
            </a>
            <div className="dropdown-menu">
              <MyAccount
                user={{
                  fname: user.fname,
                  lname: user.lname,
                  email: user.email,
                  profile_pic: user.profile_pic,
                }}
              />
            </div>
          </div>
        </li>
      ) : null}

      <li className={`list-inline-item add_listing ${float}`}>
        <Link href={!token ? "/login" : "/create-listing"}>
          <a className="add-listing-btn">
            <span className="flaticon-plus"></span>
            <span className="dn-lg"> Add Listing</span>
          </a>
        </Link>
      </li>
      {/* End .dropitem */}

      {/* End .dropitem */}
    </ul>
  );
};

export default HeaderMenuContent;
