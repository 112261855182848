import Link from "next/link";
import Social from "./Social";
import SubscribeForm from "./SubscribeForm";
// import logoSrc from "assets/images/logo/listabiz-logo-only-transparent-white.png";

const Footer = () => {
  return (
    <>
      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 pr0 pl0">
        <div className="footer_about_widget flex justify-center h-full">
          {/* <p>
            We’re reimagining how you buy, sell and rent. It’s now easier to get
            into a business you love. So let’s do this, together.
          </p> */}
          <img
            src="/assets/images/logo/listabiz-logo-only-transparent-white.png"
            alt="liztabiz logo"
            className="w-[104px] h-[115px]"
          />
        </div>
      </div>
      {/* End .col */}

      <div className="col-sm-8 col-md-3 col-lg-3 col-xl-3 max-[767px]:text-center">
        <div className="footer_qlink_widget ">
          <h4>Quick Links</h4>
          <ul className="list-unstyled">
            <li>
              <Link href="/listing/search">
                <a>Our Listings</a>
              </Link>
            </li>
            <li>
              <Link href="/about-us">
                <a>About Us</a>
              </Link>
            </li>
            <li>
              <Link href="/contact">
                <a>Contact Us</a>
              </Link>
            </li>
            <li>
              <Link href="/terms">
                <a>Terms and Conditions</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* End .col */}

      {/* <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div className="footer_contact_widget">
          <h4>Contact Us</h4>
          <ul className="list-unstyled">
            <li>
              <a href="mailto:info@liztabiz.com">info@liztabiz.com</a>
            </li>
            <li>
              <a href="#">Collins Street West, Victoria</a>
            </li>
            <li>
              <a href="#">8007, Australia.</a>
            </li>
            <li>
              <a href="tel:+4733378901">+1 246-345-0699</a>
            </li>
            <li>
              <a href="tel:+4733378901">+1 246-345-0695</a>
            </li>
          </ul>
        </div>
      </div> */}
      {/* End .col */}

      <div className="col-sm-8 col-md-3 col-lg-3 col-xl-3 max-[767px]:text-center">
        <div className="footer_social_widget ">
          <h4>Follow us</h4>
          <ul className="mb30">
            <Social />
          </ul>
          <h4>Subscribe to our newsletter</h4>
          <SubscribeForm />
        </div>
      </div>
    </>
  );
};

export default Footer;
