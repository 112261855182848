import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  SidebarHeader,
  SidebarFooter,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import { toast } from "react-toastify";

import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector, useDispatch, batch } from "react-redux";
import { useContext } from "react";

import UserContext from "../../../features/user/UserContext";

import { clearUserDetails } from "../../../features/user/userSlice";
import { clearCallbackUrl, clearToken } from "../../../features/auth/authSlice";

const MobileMenuContent = () => {
  const route = useRouter();
  const dispatch = useDispatch();

  const { user, setUser } = useContext(UserContext);
  const { token } = useSelector((state) => state.auth);

  return (
    <ProSidebar>
      <SidebarHeader>
        <div className="sidebar-header">
          <Link href="/">
            <a className="sidebar-header-inner">
              <img
                className="nav_logo_img img-fluid mt20"
                src="/assets/images/logo/listabiz-logo-only-transparent-black.png"
                alt="header-logo.png"
              />
              <span className="brand-text">LiztaBiz</span>
            </a>
          </Link>
          {/* End .logo */}

          <div
            className="fix-icon"
            data-bs-dismiss="offcanvas"
            aria-label="Close">
            <span className="flaticon-close"></span>
          </div>
          {/* Mobile Menu close icon */}
        </div>

        {/* End logo */}
      </SidebarHeader>

      <SidebarContent>
        <Menu>
          <MenuItem>
            <Link href="/listing/search">
              <a
                className={
                  route.pathname === "/listing/search" ? "ui-active" : undefined
                }>
                Lizta
              </a>
            </Link>
          </MenuItem>

          {/* <MenuItem>
            <Link href="/">
              <a className={route.pathname === "/" ? "ui-active" : undefined}>
                Home
              </a>
            </Link>
          </MenuItem> */}
          {/* End Home Home */}

          <MenuItem>
            <Link href="/about-us">
              <a
                className={
                  route.pathname === "/about-us" ? "ui-active" : undefined
                }>
                About Us
              </a>
            </Link>
          </MenuItem>

          <MenuItem>
            <Link href="/contact">
              <a
                className={
                  route.pathname === "/contact" ? "ui-active" : undefined
                }>
                Contact
              </a>
            </Link>
          </MenuItem>

          {!(user && token) ? (
            <>
              <MenuItem>
                <Link href="/login">
                  <a
                    className={
                      route.pathname === "/login" ? "ui-active" : undefined
                    }>
                    <span className="flaticon-user"></span> Login
                  </a>
                </Link>
              </MenuItem>

              <MenuItem>
                <Link href="/register">
                  <a
                    className={
                      route.pathname === "/register" ? "ui-active" : undefined
                    }>
                    <span className="flaticon-edit"></span> Register
                  </a>
                </Link>
              </MenuItem>
            </>
          ) : null}

          {user && token && (
            <MenuItem>
              <Link href="#">
                <a
                  // className={
                  //   route.pathname === "/contact" ? "ui-active" : undefined
                  // }
                  onClick={async () => {
                    new Promise((res, rej) =>
                      res(
                        batch(() => {
                          setUser(null);
                          dispatch(clearToken());
                          dispatch(clearCallbackUrl());
                        })
                      )
                    ).then(() => {
                      window.location.href = "/login";
                    });
                  }}>
                  Log Out
                </a>
              </Link>
            </MenuItem>
          )}
        </Menu>
      </SidebarContent>

      {/* <SidebarFooter>
        <Link href="/create-listing">
          <a className="btn btn-block btn-lg btn-thm circle">
            <span className="flaticon-plus"></span> Add Listing
          </a>
        </Link>
      </SidebarFooter> */}
    </ProSidebar>
  );
};

export default MobileMenuContent;
