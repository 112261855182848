import Link from "next/link";

const CopyrightFooter = () => {
  const menuItems = [
    // { id: 1, name: "Home", routeLink: "/" },
    // { id: 2, name: "Listings", routeLink: "/listing/search" },
    // { id: 4, name: "About Us", routeLink: "/about-us" },
    // { id: 6, name: "Contact", routeLink: "/contact" },
    // { id: 7, name: "Terms and Conditions", routeLink: "#" },
    { id: 8, name: "© 2023 LiztaBiz.", routeLink: "#" },
  ];

  return (
    <div className="row">
      <div className="col-lg-6 col-xl-6">
        <div className="footer_menu_widget">
          {/* <ul>
            {menuItems.map((item) => (
              <li className="list-inline-item" key={item.id}>
                <p className="text-[#0d638f]">{item.name}</p>
              </li>
            ))}
          </ul> */}
        </div>
      </div>
      {/* End .col */}
      {/* 
      <div className="col-lg-6 col-xl-6">
        <div className="copyright-widget text-end">
          <p>
            &copy; {new Date().getFullYear()} by{" "}
            <a
              href="https://themeforest.net/user/ib-themes"
              target="_blank"
              rel="noreferrer">
              ib-themes
            </a>
            . All rights reserved.
          </p>
        </div>
      </div> */}
      {/* End .col */}
    </div>
  );
};

export default CopyrightFooter;
