import Link from "next/link";
import { useRouter } from "next/router";

import { useState, useRef, useEffect, useContext } from "react";
import UserContext from "../../../features/user/UserContext";
import { useDispatch, useSelector, batch } from "react-redux";

import {
  useLoginMutation,
  useSignupMutation,
} from "../../../features/auth/authAPI";
import { setToken } from "../../../features/auth/authSlice";
import { setUserDetails } from "../../../features/user/userSlice";

import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import ReCAPTCHA from "react-google-recaptcha";

import Sentry from "@sentry/nextjs";

import $ from "jquery";

import resetBodyStyle from "../../../utils/resetBodyStyle";
import validateEmail from "../../../utils/validate.email.js";

let errorMessageState = {
  emailError: null,
  passwordError: null,
};

const LoginSignup = () => {
  const router = useRouter();

  const { user, setUser } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errorObj, setErrorObj] = useState(errorMessageState);

  const resetErrorObj = () => {
    setErrorObj(errorMessageState);
  };

  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const recaptchaRef = useRef();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      let res = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }
      );

      let data = await res.json();

      if (data) {
        const x = await fetch(`${process.env.SLS_URL}/auth/login/google`, {
          method: "POST",
          body: JSON.stringify({
            email: data.email,
            id: data.id,
            fname: data.given_name,
            lname: data.family_name,
          }),
        });

        const datax = await x.json();

        if (datax) {
          new Promise((res, rej) => {
            res(
              batch(() => {
                dispatch(setUserDetails(datax.user));
                setUser(datax.user);
                dispatch(setToken(datax.token));
              })
            );
          }).finally(() => {
            setIsLoading(false);
            $(".bd-example-modal-lg")[0].hidden = true;
            resetBodyStyle(document);
            router.push(window.location.href);

            if (datax.newUser) {
              toast.success(`Welcome, ${datax.user.fname}!`);
            } else {
              toast.success(`Welcome back, ${datax.user.fname}!`);
            }
          });
        }
      }
    },
    onError: (e) => {
      console.log(e);
      resetBodyStyle(document);
      toast.error("An unexpected error occured. Please try again.");
      setIsLoading(false);
    },
    onNonOAuthError: () => {
      setIsLoading(false);
      resetBodyStyle(document);
    },
  });

  const facebookLogin = async (fbResponse) => {
    try {
      setIsLoading(true);
      let name = fbResponse.name.split(" ");

      let fb_lname = name.pop(); // bug here
      let fb_fname = name.join(" ");

      const res = await fetch(`${process.env.SLS_URL}/auth/login/facebook`, {
        method: "POST",
        body: JSON.stringify({
          email: fbResponse.email,
          id: fbResponse.userID,
          fname: fb_fname,
          lname: fb_lname,
        }),
      });

      if (res) {
        const data = await res.json();

        if (data) {
          new Promise((res, rej) => {
            res(
              batch(() => {
                dispatch(setUserDetails(data.user));
                setUser(data.user);
                dispatch(setToken(data.token));
              })
            );
          }).finally(() => {
            setIsLoading(false);
            $(".bd-example-modal-lg")[0].hidden = true;
            router.push(window.location.href);

            if (data.newUser) {
              toast.success(`Welcome, ${data.user.fname}!`);
            } else {
              toast.success(`Welcome back, ${data.user.fname}!`);
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("An unexpected error occured. Please try again later.");
      setIsLoading(false);

      if (Sentry) {
        Sentry.captureException(e);
      }
    }

    resetBodyStyle(document);
  };

  useEffect(() => {
    recaptchaRef.current.reset();
  }, []);

  return (
    <div className="modal-content">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}
        // onChange={onChange}
      />
      <div className="modal-header">
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          className="btn-close"
        ></button>
      </div>
      {/* End .modal-header */}

      <div className="modal-body container pb20">
        {/* End .row */}

        <div className="tab-content container" id="myTabContent">
          <div
            className="row mt25 tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="col-lg-6 col-xl-6">
              <div className="login_thumb">
                <img
                  className="img-fluid w100"
                  src="/assets/images/resource/login.jpg"
                  alt="login.jpg"
                />
              </div>
            </div>
            {/* End col */}

            {/* LOGIN / LOG IN */}

            <div className="col-lg-6 col-xl-6">
              <div className="login_form">
                <form action="#">
                  <div className="row mt25">
                    <div className="col-lg-12">
                      <FacebookLogin
                        appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
                        fields="name,email,picture"
                        cssClass="btn btn-fb w-100"
                        icon={
                          <i className="fa fa-facebook float-start mt5"></i>
                        }
                        callback={(res) => facebookLogin(res)}
                        disableMobileRedirect={true}
                      />
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="button"
                        className="btn btn-googl w-100"
                        onClick={(e) => {
                          setIsLoading(true);
                          googleLogin();
                        }}
                      >
                        <i className="fa fa-google float-start mt5"></i> Login
                        with Google
                      </button>
                    </div>
                  </div>
                  {/* End .row */}

                  <hr />
                  <div className="form-group input-group  mb-3">
                    <input
                      type="email"
                      id="loginPopupEmail"
                      className="form-control relative"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa fa-envelope-o"></i>
                      </div>
                    </div>
                    {errorObj.emailError && (
                      <p className="absolute top-[52px] right-[10px] text-[10px] text-red-500">
                        {errorObj.emailError}
                      </p>
                    )}
                  </div>
                  {/* End .row */}

                  <div className="input-group form-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control relative"
                      id="loginPopupPassword"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i
                          className={
                            showPassword ? "flaticon-view" : "flaticon-password"
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      </div>
                    </div>
                    {errorObj.passwordError && (
                      <p className="absolute top-[52px] right-[10px] text-[10px] text-red-500">
                        {errorObj.passwordError}
                      </p>
                    )}
                  </div>
                  {/* End input-group */}

                  {/* <div className="form-group form-check custom-checkbox mb-3">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="remeberMe"
                                        />
                                        <label
                                            className="form-check-label form-check-label"
                                            htmlFor="remeberMe"
                                        >
                                            Remember me
                                        </label>

                                        <a
                                            className="btn-fpswd float-end"
                                            href="#"
                                        >
                                            Lost your password?
                                        </a>
                                    </div> */}
                  {/* End remember me checkbox */}

                  <button
                    type="submit"
                    className="btn btn-log w-100 btn-thm"
                    onClick={async (e) => {
                      try {
                        e.preventDefault();

                        setIsLoading(true);

                        let emailInput =
                          document.querySelector("#loginPopupEmail");
                        let passwordInput = document.querySelector(
                          "#loginPopupPassword"
                        );

                        let inputs = [emailInput, passwordInput];

                        for (const input of inputs) {
                          input.classList.remove("invalid");
                        }

                        resetErrorObj();

                        let inputError = false;

                        let errorMessages = {};

                        if (!email || !email.length) {
                          emailInput.classList.add("invalid");
                          errorMessages.emailError = "Please input an email";

                          inputError = true;
                        } else if (email && !validateEmail(email)) {
                          emailInput.classList.add("invalid");
                          errorMessages.emailError =
                            "Please input a valid email";

                          inputError = true;
                        }

                        if (!password || !password.length) {
                          passwordInput.classList.add("invalid");
                          errorMessages.passwordError =
                            "Please input your password";

                          inputError = true;
                        }

                        if (inputError) {
                          setErrorObj({
                            ...errorMessageState,
                            ...errorMessages,
                          });
                          setIsLoading(false);
                          return;
                        }

                        const recaptchaToken =
                          await recaptchaRef.current.executeAsync();

                        const recaptchaRes = await fetch(
                          `${process.env.SLS_URL}/auth/verify-recaptcha`,
                          {
                            method: "POST",
                            body: JSON.stringify({ token: recaptchaToken }),
                          }
                        );

                        const recaptchaJson = await recaptchaRes.json();

                        if (!recaptchaJson || !recaptchaJson.status === 1) {
                          toast.error(
                            "Failed to verify reCAPTCHA. Please try again."
                          );
                          return;
                        }

                        const data = await login({
                          email,
                          password,
                        }).unwrap();

                        recaptchaRef.current.reset();

                        if (data) {
                          if ("status" in data && data.status === 0) {
                            setIsLoading(false);

                            toast.error(data.message);
                            if (Sentry) {
                              Sentry.captureException(data.message);
                            }

                            return;
                          }

                          new Promise((res, rej) => {
                            res(
                              batch(() => {
                                dispatch(setUserDetails(data.user));
                                setUser(data.user);
                                dispatch(setToken(data.token));
                              })
                            );
                          }).finally(() => {
                            setIsLoading(false);
                            $(".bd-example-modal-lg")[0].hidden = true;

                            router.push(window.location.href);
                            toast.success(`Welcome back, ${data.user.fname}!`);
                          });
                        }
                      } catch (error) {
                        setIsLoading(false);
                        console.log("error ", error);

                        toast.error(
                          error.data
                            ? error.data.message
                            : error.message
                            ? error.message
                            : "An unexpected error occured. Please try again later."
                        );
                      }

                      resetBodyStyle(document);
                    }}
                  >
                    {isLoading ? (
                      <BeatLoader
                        loading={isLoading}
                        color={"white"}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        size={10}
                      />
                    ) : (
                      "Log In"
                    )}
                  </button>
                  {/* End submit button */}

                  <p className="text-center">
                    Dont have an account?{" "}
                    <Link
                      href="/register"
                      legacyBehavior={false}
                      onClick={(e) => resetBodyStyle(document)}
                    >
                      Register
                    </Link>
                  </p>
                </form>
              </div>
              {/* End .col .login_form */}
            </div>
          </div>
          {/* End .tab-pane */}

          {/*  REGISTER FORM/ SIGN UP FORM/ SIGNUP FORM */}

          {/* End .tab-pane */}
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
