import { useState, useRef, useEffect } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";

const SubscribeForm = () => {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!email || !email.indexOf("@")) return;

      const recaptchaToken = await recaptchaRef.current.executeAsync();

      const recaptchaRes = await fetch(
        `${process.env.SLS_URL}/auth/verify-recaptcha`,
        {
          method: "POST",
          body: JSON.stringify({ token: recaptchaToken }),
        }
      );

      const recaptchaJson = await recaptchaRes.json();

      if (!recaptchaJson || !recaptchaJson.status === 1) {
        toast.error("Failed to verify reCAPTCHA. Please try again.");
        return;
      }

      setIsLoading(true);

      const subscribeToNewsletterRes = await fetch(
        `${process.env.SLS_URL}/news-letter/subscribe`,
        {
          method: "POST",
          body: JSON.stringify({ email }),
        }
      );

      const subscribeToNewsletterJson = await subscribeToNewsletterRes.json();

      if (
        subscribeToNewsletterJson &&
        "status" in subscribeToNewsletterJson &&
        subscribeToNewsletterJson.status === 1
      ) {
        toast.success(subscribeToNewsletterJson.message);
        setEmail(null);
      } else if (subscribeToNewsletterJson.status === 0) {
        throw new Error(subscribeToNewsletterJson.message);
      } else {
        throw new Error(
          "Newsletter subscription failed. Please try again later."
        );
      }

      recaptchaRef.current.reset();
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);

      recaptchaRef.current.reset();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    recaptchaRef.current.reset();
  }, []);

  return (
    <form className="footer_mailchimp_form" onSubmit={handleSubmit}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}
        // onChange={onChange}
      />
      <div className="d-flex align-items-center max-lg:justify-center">
        <div className="col-auto">
          <input
            type="email"
            required
            className="form-control mb-2"
            id="inlineFormInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email"
          />
        </div>

        <div className="col-auto ms-2">
          <button type="submit" className="btn btn-primary mb-2">
            {isLoading ? (
              <BounceLoader
                loading={isLoading}
                color={"white"}
                aria-label="Loading Spinner"
                data-testid="loader"
                size={10}
              />
            ) : (
              <i className="fa fa-angle-right"></i>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SubscribeForm;
