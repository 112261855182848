import Link from "next/link";
import { useRouter } from "next/router";
import { isSinglePageActive } from "../../../../utils/daynamicNavigation";
import { useDispatch, batch } from "react-redux";
import { useContext } from "react";
import UserContext from "../../../../features/user/UserContext";
import { toast } from "react-toastify";
import {
  clearCallbackUrl,
  clearToken,
  setToken,
} from "../../../../features/auth/authSlice";
import { clearUserDetails } from "../../../../features/user/userSlice";

const MyAccount = ({ user }) => {
  const dispatch = useDispatch();
  const route = useRouter();
  const { setUser } = useContext(UserContext);

  return (
    <>
      <div className="user_set_header">
        <img
          className="float-start !mr-4"
          // src={`/assets/images/team/e1.png`}
          style={{ width: "45px", height: "45px", objectFit: "cover" }}
          src={
            user.profile_pic ||
            `https://ui-avatars.com/api/?name=${user.fname}+${user.lname}`
          }
          alt="e1.png"
        />
        <p>
          {user.fname} {user.lname} <br />
          <span className="address" style={{ overflowWrap: "break-word" }}>
            {user.email}
          </span>
        </p>
      </div>
      {/* End user_set_header */}

      <div className="user_setting_content">
        <Link href="/my-profile">
          <a
            className="dropdown-item"
            style={
              isSinglePageActive(`/my-profile`, route.pathname)
                ? { color: "#ff5a5f" }
                : undefined
            }>
            My Profile
          </a>
        </Link>

        <Link href="/my-listings">
          <a
            className="dropdown-item"
            style={
              isSinglePageActive(`/my-listings`, route.pathname)
                ? { color: "#ff5a5f" }
                : undefined
            }>
            My Listings
          </a>
        </Link>

        <Link href="/my-inquiries">
          <a
            className="dropdown-item"
            style={
              isSinglePageActive(`/my-inquiries`, route.pathname)
                ? { color: "#ff5a5f" }
                : undefined
            }>
            My Inquiries
          </a>
        </Link>

        <Link href="#">
          <>
            <a
              className="dropdown-item"
              onClick={async () => {
                new Promise((res, rej) =>
                  res(
                    batch(() => {
                      setUser(null);
                      dispatch(clearToken());
                      dispatch(clearCallbackUrl());
                    })
                  )
                ).finally(() => {
                  window.location.href = "/login";
                });
              }}>
              Log Out
            </a>
          </>
        </Link>
      </div>
    </>
  );
};

export default MyAccount;
